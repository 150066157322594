<template>
  <div>
    <main class="index">
      <hero-image-section />
      <character-section />
      <news-section />
      <event-info-section />
      <food-truck-section />
      <sns-section :snsLinks="snsLinks" />
      <shop-section />
      <contact-section />
      <sponsor-section />
    </main>
  </div>
</template>

<script>
import HeroImageSection from "@/components/HeroImageSection";
import CharacterSection from "@/components/CharacterSection";
import NewsSection from "@/components/NewsSection";
import EventInfoSection from "@/components/EventInfoSection";
import FoodTruckSection from "@/components/FoodTruckSection";
import SnsSection from "@/components/SnsSection";
import ShopSection from "@/components/ShopSection";
import ContactSection from "@/components/ContactSection";
import SponsorSection from "@/components/SponsorSection.vue";

export default {
  name: "Index",
  metaInfo: {
    title: "トップ",
  },
  components: {
    HeroImageSection,
    CharacterSection,
    NewsSection,
    EventInfoSection,
    FoodTruckSection,
    SnsSection,
    ShopSection,
    ContactSection,
    SponsorSection,
  },
  data() {
    return {
      snsLinks: {
        instagram: {
          url: "https://instagram.com/bincho_fire",
          img: require("@/assets/img/ico_instagram.png"),
        },
        twitter: {
          url: "https://twitter.com/binchofire",
          img: require("@/assets/img/ico_twitter.png"),
        },
      },
    };
  },
  mounted() {
    if (this.$route.hash) {
      this.scrollToHash();
    }
  },
  methods: {
    scrollToHash() {
      const hash = this.$route.hash;
      this.$nextTick(() => {
        this.$scrollTo(hash, 500);
      });
    },
  },
};
</script>
