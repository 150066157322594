<template>
  <section class="index-kv">
    <div class="index-kv-body">
      <img
        src="@/assets/img/kv01_pc.jpg"
        class="pc"
        alt="炭火戦士ビンチョウファイヤー"
      />
      <img
        src="@/assets/img/kv01_sp.jpg"
        class="sp"
        alt="炭火戦士ビンチョウファイヤー"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: "HeroImageSection",
  components: {},
  data() {
    return {
      items: [],
    };
  },
};
</script>
