<template>
  <div class="pagination">
    <router-link
      :to="{ query: Object.assign({}, this.$route.query, { [targetPage]: prevPage }) }"
      class="prev"
      v-if="currentPage > 1"
      @click.native="$scrollTo(scrollToElement, 500)"
    >
      &lt;前へ
    </router-link>
    <div class="total">ページ {{currentPage}}/{{totalPage}}</div>
    <router-link
      :to="{ query: Object.assign({}, this.$route.query, { [targetPage]: nextPage }) }"
      class="next"
      v-if="currentPage < totalPage"
      @click.native="$scrollTo(scrollToElement, 200)"
    >
      次へ&gt;
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    scrollToElement: {
      type: String,
      required: false,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    totalPage: {
      type: Number,
      required: true,
    },
    targetPage: {
      type: String,
      required: true,
    },
  },
  computed: {
    prevPage() {
      return Math.max(this.currentPage - 1, 1);
    },
    nextPage() {
      return Math.min(this.currentPage + 1, this.totalPage);
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  @include flex($justifyContent: flex-end);
  margin: 0 5rem;
  @media screen and (max-width: 599px) {
    margin: 0 4%;
  }
}
.prev {
  color: white;;
}
.next {
  color: white;
}
.total {
  color: gray;
  text-align: center;
  padding: 0 1rem;
}
</style>
