<template>
  <section class="kitchen" id="kitchen">
    <h2><img src="@/assets/img/heading_kitchen.png" alt="キッチンカー" /></h2>
    <div class="kitchen-body">
      <div class="kitchen-desc">
        <div class="kitchen-img">
          <img src="@/assets/img/kitchencar.jpg" alt="" />
        </div>
        <div class="kitchen-txt">
          <h3>Hero's Kitchen</h3>
          <p>
            日本初のヒーローが運営するキッチンカー<br />ヒーローが調理販売・グリーティング等<br />パフォーマンスを兼ねてイベントを盛り上げのが目的です。
          </p>
        </div>
      </div>
      <div class="kitchen-menu">
        <h4>MENU</h4>
        <div class="kitchen-slider">
          <div ref="swiper" class="swiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <img src="@/assets/img/food01.jpg" alt="" />
              </div>
              <div class="swiper-slide">
                <img src="@/assets/img/food02.jpg" alt="" />
              </div>
              <div class="swiper-slide">
                <img src="@/assets/img/food03.jpg" alt="" />
              </div>
              <div class="swiper-slide">
                <img src="@/assets/img/food04.jpg" alt="" />
              </div>
              <div class="swiper-slide">
                <img src="@/assets/img/food05.jpg" alt="" />
              </div>
            </div>
          </div>
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>

        <div class="kitchen-menu-img">
          <img src="@/assets/img/menu_pc.png" alt="" class="pc" />
          <img src="@/assets/img/menu_sp.png" alt="" class="sp" />
        </div>
      </div>
      <div class="kitchen-schedule">
        <h4>CALENDAR</h4>
        <div class="kitchen-calendar">
          <iframe
            src="https://calendar.google.com/calendar/embed?src=4a8e2053f8c8e3dcd2429d8c09f3dfd927330463f96f414c2cdc5cfcd7f18cd4%40group.calendar.google.com&ctz=Asia%2FTokyo"
            style="border: 0" width="600" height="400" frameborder="0" scrolling="no"></iframe>
        </div>
        <p>
          屋号：大自然&emsp;<br class="sp" />営業許可&emsp;福岡県全域<br />本拠地&emsp;北九州市<br class="sp" />車両サイズ&emsp;3トン
        </p>
        <p>
          <span>事業再構築</span>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper from 'swiper/swiper-bundle'
import 'swiper/swiper-bundle.css'

export default {
  name: "FoodTruckSection",
  components: {},
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    new Swiper(this.$refs.swiper, {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        599: {
          slidesPerView: 3,
        },
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      loop: true,
      centeredSlides: true,
      slidesPerView: 1,
      loopedSlides: 2,
      spaceBetween: 16,
      speed: 600,
    })
  },
};
</script>
<style lang="scss">
.kitchen {
  text-align: center;

  h3 {
    margin-bottom: 1.5em;
    font-size: 24px;
    font-weight: 600;
  }

  h4 {
    margin-bottom: 1.5em;
    font-size: 20px;
    font-weight: 600;
  }

  &-body {
    margin: 7rem auto;
  }

  &-txt {
    margin: 7rem auto 3rem;
    line-height: 2;
  }

  &-menu {
    margin: 7rem auto;
  }

  &-slider {
    position: relative;
    margin: 1em auto;

    .swiper {
      width: 92%;
    }

    .swiper-slide {
      width: 100%;
      height: 100%;
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: 40px;
      height: 75px;
    }

    .swiper-button-prev::after,
    .swiper-button-next::after {
      background-image: url('~@/assets/img/ico_arrow.png');
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      margin: auto;
      width: 45px;
      height: 75px;
      opacity: 0.65;
    }

    .swiper-button-prev::after {
      transform: scale(-1, 1);
    }
  }

  &-schedule {
    p {
      &:first-of-type {
        margin: 5rem auto 3rem;
      }

      &:last-of-type {
        margin: 3rem auto 5rem;
        text-align: right;

        span {
          display: inline-block;
          margin-right: 4%;
          padding: .5em 1.8em;
          border: 1px solid $clr_white;
        }
      }
    }
  }

  &-calendar {
    iframe {
      max-width: 92%;
    }
  }

  @media screen and (max-width: 599px) {
    h3 {
      margin-bottom: .5em;
    }

    &-body {
      width: 92%;
      margin: 3rem auto;
    }

    &-desc {
      @include flex;
      flex-direction: column-reverse;
    }

    &-txt {
      margin: 0 auto 3rem;
    }

    &-menu {
      margin: 3rem auto;
    }

    &-calendar {
      iframe {
        height: 40rem;
      }
    }

    &-schedule {
      p {
        width: 92%;
        margin: auto;

        &:first-of-type {
          text-align: left;
          margin: 3rem auto;
        }
      }
    }
  }
}
</style>
