<template>
  <section class="news" id="news-section">
    <h2><img src="@/assets/img/heading_latest.png" alt="新着情報" /></h2>
    <ul>
      <li v-for="item in items" :key="item.id">
        <span class="date">{{ item.published_at | format_date }}</span>
        <a v-if="item.url" :href="item.url" :target="item.target">
          {{ item.title }}
        </a>
        <span v-else>{{ item.title }}</span>
      </li>
    </ul>
    <prev-next
      :scroll-to-element="'#news-section'"
      :currentPage="pagination.current_page"
      :totalPage="pagination.total_pages"
      :targetPage="targetPage"
    />
  </section>
</template>

<script>
import dayjs from "dayjs";
import PrevNext from "@/components/pagination/PrevNext";

export default {
  name: "NewsSection",
  components: { PrevNext },
  data() {
    return {
      items: [],
      pagination: {
        current_page: 1,
        total_pages: 1,
        per_page: 5,
      },
      targetPage: "newsPage",
    };
  },
  filters: {
    format_date(date) {
      return dayjs(date).format("YY/MM/DD");
    },
  },
  watch: {
    "$route.query.newsPage"(to) {
      if (to) {
        this.fetchNewsData(to);
      }
    },
  },
  created() {
    const newsPage = this.$route.query.newsPage || 1;
    this.fetchNewsData(newsPage);
  },
  methods: {
    fetchNewsData(newsPage) {
      this.$http
        .get(`news?page=${newsPage}&perPage=${this.pagination.per_page}`)
        .then((res) => {
          this.items = res.data.data;
          this.pagination = res.data.meta.pagination;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.news {
  ul {
    margin: 2rem 5rem;
    li {
      padding: 1em 0.5em;
      border: 1px solid #000;
      background: rgba($clr_black, 0.5);
      font-weight: 500;
      .date {
        display: block;
        margin-bottom: 0.2em;
        color: $clr_accent;
      }
      a {
        text-decoration: underline;
      }
      & + li {
        margin-top: 0.4em;
      }
    }
  }
  @media screen and (max-width: 599px) {
    ul {
      margin: 1rem 4%;
    }
  }
}
</style>
