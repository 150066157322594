<template>
  <section class="contact" id="contact-section">
    <h2>
      <img src="@/assets/img/heading_contact.png" alt="お問い合わせ" />
    </h2>
    <div class="contact-body">
      <p>
        ビンチョウファイヤーへのお問い合わせは<br />コチラまでお願い致します
      </p>
      <ul>
        <li>・ヒーローショー等の出演</li>
        <li>・スポンサー企業募集中</li>
        <li>・当HPにバナー掲載等</li>
      </ul>
      <p>bf.binchofire@gmail.com</p>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactSection",
  components: {},
  data() {
    return {
      items: [],
    };
  },
};
</script>
<style lang="scss" scoped>
.contact {
  &-body {
    margin: 2rem 3%;
    width: 55%;
    line-height: 1.6;
    p {
      &:last-of-type {
        margin-top: 0.5em;
        font-size: 20px;
      }
    }
  }
  @media screen and (max-width: 599px) {
    &-body {
      width: 92%;
      margin: auto;
    }
  }
}
</style>
