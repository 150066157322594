<template>
  <section class="character" id="character-section">
    <h2><img src="@/assets/img/heading_character.png" alt="キャラクター" /></h2>
    <div class="character-body">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 2000 900"
        preserveAspectRatio="xMinYMin meet"
        class="pc"
      >
        <image width="2000" height="900" href="@/assets/img/character_pc.jpg" />
        <circle
          class="click-area"
          @click="showModal(require('@/assets/img/kyara_popup.png'))"
          cx="1825"
          cy="160"
          r="75"
        ></circle>
        <circle
          class="click-area"
          @click="showModal(require('@/assets/img/kyara_popup2.png'))"
          cx="1825"
          cy="350"
          r="75"
        ></circle>
        <circle
          class="click-area"
          @click="showModal(require('@/assets/img/kyara_popup3.png'))"
          cx="1825"
          cy="550"
          r="75"
        ></circle>
        <circle
          class="click-area"
          @click="showModal(require('@/assets/img/kyara_popup4.png'))"
          cx="1825"
          cy="740"
          r="75"
        ></circle>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 600 800"
        preserveAspectRatio="xMinYMin meet"
        class="sp"
      >
        <image width="600" height="800" href="@/assets/img/character_sp.jpg" />
        <circle
          class="click-area"
          @click="showModal(require('@/assets/img/kyara_popup.png'))"
          cx="405"
          cy="258"
          r="45"
        ></circle>
        <circle
          class="click-area"
          @click="showModal(require('@/assets/img/kyara_popup2.png'))"
          cx="510"
          cy="258"
          r="45"
        ></circle>
        <circle
          class="click-area"
          @click="showModal(require('@/assets/img/kyara_popup3.png'))"
          cx="405"
          cy="363"
          r="45"
        ></circle>
        <circle
          class="click-area"
          @click="showModal(require('@/assets/img/kyara_popup4.png'))"
          cx="510"
          cy="363"
          r="45"
        ></circle>
      </svg>
      <modal
        name="character-modal"
        :adaptive="true"
        height="auto"
        width="90%"
        :max-width="500"
        transition="fade"
        @before-open="modal.isOpened = true"
        @before-close="modal.isOpened = false"
      >
        <div v-scroll-lock="modal.isOpened">
          <img :src="modal.src" />
          <div class="close-box" @click="$modal.hide('character-modal')">
            <span>×</span>
          </div>
        </div>
      </modal>
      <transition name="fade" appear>
        <div v-show="modal.isLoading" class="spinner-loading">
          <font-awesome-icon
            :icon="['fas', 'spinner']"
            size="3x"
            spin
          ></font-awesome-icon>
        </div>
      </transition>
    </div>
    <div class="character-introduction">
      <p>
        <span>「完食御礼！ 命に感謝！</span
        ><span>炭火戦士 ビンチョウファイヤー！</span
        ><span>イタダキマス。」</span>
      </p>
      <p>
        &emsp;動物が好きな一方、その動物の命をいただく場所である焼き鳥屋を開いている。<br />&emsp;命に感謝し、美味しく食べることを決して忘れないで欲しいと皆に伝えるヒーローである。<br />&emsp;自然の「恵み」に感謝するとともに、台風や地震など自然の「脅威」についても子どもたちに教える。
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "CharacterSection",
  components: {},
  data() {
    return {
      items: [],
      modal: {
        src: "",
        isLoading: false,
        isOpened: false,
      },
    };
  },
  methods: {
    showModal(filePath) {
      this.modal.isLoading = true;
      const preloadImage = new Image();
      preloadImage.onload = () => {
        this.$modal.show("character-modal");
        this.modal.isLoading = false;
      };
      preloadImage.src = this.modal.src = filePath;
    },
  },
};
</script>

<style lang="scss" scoped>
.character {
  &-introduction {
    padding: 5rem 4%;
    text-align: center;
    p {
      line-height: 2;
      &:first-of-type {
        margin-bottom: 1em;
        font-size: 22px;
      }
    }
    @media screen and (max-width: 599px) {
      background: #000 url("~@/assets/img/bg03.png") repeat-y center 100% /
        cover;
      p {
        &:first-of-type {
          font-size: 16px;
        }
        &:last-of-type {
          text-align: left;
        }
        span {
          display: block;
          &:first-of-type {
            text-align: left;
          }
          &:last-of-type {
            text-align: right;
          }
        }
      }
    }
  }
}
svg {
  .click-area {
    opacity: 0;
    cursor: pointer;
  }
}
.spinner-loading {
  position: fixed;
  left: 50vw;
  top: 50vh;
  color: white;
  z-index: 9999;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.vm--container::v-deep {
  .vm--modal {
    background: rgba(0, 0, 0, 0.5);
    .close-box {
      width: 10%;
      height: 9%;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      background-color: #7d0000;
      opacity: 1;
      span {
        font-size: 2.5rem;
        color: white;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.8;
      }
    }
  }
}
</style>
