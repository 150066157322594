import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueScrollTo from 'vue-scrollto'
import VModal from 'vue-js-modal'
import VScrollLock from 'v-scroll-lock'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faSpinner)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

// used in vue-meta
Vue.prototype.$defaultTitle = process.env.VUE_APP_TITLE
Vue.prototype.$defaultTitleTemplate = process.env.VUE_APP_TITLE_TEMPLATE

// axios
Vue.prototype.$http = axios.create(
  { baseURL: process.env.VUE_APP_API_BASE_URL },
);

// vue-scrollto
Vue.use(VueScrollTo, {
  offset: -40,
  lazy: false,
})

// vue-js-modal
Vue.use(VModal)

// v-scroll-lock
Vue.use(VScrollLock, {
  bodyScrollOptions: {
    reserveScrollBarGap: true,
  },
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
