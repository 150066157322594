<template>
  <header class="g-header">
    <div id="burger" class="g-header-bar" v-scroll-lock="isSideMenuOpened">
      <Slide
        right
        :closeOnNavigation="true"
        @openMenu="isSideMenuOpened = true"
        @closeMenu="isSideMenuOpened = false"
      >
        <li v-for="(link, i) in headerLinks" :key="i">
          <router-link :to="link.to" v-scroll-to="link.scrollTo" exact
            >&diams;{{ link.text }}</router-link
          >
        </li>
      </Slide>
    </div>
    <div id="overlay" v-if="isSideMenuOpened"></div>
  </header>
</template>

<script>
import { Slide } from "vue-burger-menu";

export default {
  name: "Header",
  components: { Slide },
  data() {
    return {
      headerLinks: [
        {
          text: "トップ",
          scrollTo: "#app",
          to: {
            name: "index",
          },
        },
        {
          text: "キャラクター",
          scrollTo: "#character-section",
          to: {
            name: "index",
            query: this.$route.query,
            hash: "#character-section",
          },
        },
        {
          text: "新着情報",
          scrollTo: "#news-section",
          to: {
            name: "index",
            query: this.$route.query,
            hash: "#news-section",
          },
        },
        {
          text: "イベント情報",
          scrollTo: "#event-info-section",
          to: {
            name: "index",
            query: this.$route.query,
            hash: "#event-info-section",
          },
        },
        {
          text: "SNS",
          scrollTo: "#sns-section",
          to: {
            name: "index",
            query: this.$route.query,
            hash: "#sns-section",
          },
        },
        {
          text: "店舗情報",
          scrollTo: "#shop-section",
          to: {
            name: "index",
            query: this.$route.query,
            hash: "#shop-section",
          },
        },
        {
          text: "お問い合わせ",
          scrollTo: "#contact-section",
          to: {
            name: "index",
            query: this.$route.query,
            hash: "#contact-section",
          },
        },
      ],
      isSideMenuOpened: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.g-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 5rem;
  background: $clr_primary;
  &-bar {
    @include flex($justifyContent: flex-end);
    @include basicW;
    height: 100%;
  }
}
#burger::v-deep {
  .bm-burger-button {
    position: relative;
    width: 26px;
    height: 18px;
    top: 0;
    bottom: 0;
    margin-bottom: auto;
    margin-right: -24px;
    cursor: pointer;
  }
  .bm-burger-bars {
    background-color: $clr_white;
  }
  .cross-style {
    left: 16px;
    top: 24px;
  }
  .bm-cross {
    background: $clr_accent;
    width: 4px !important;
    height: 18px !important;
  }
  .bm-cross-button {
    margin: 0 1.4rem;
  }
  .bm-menu {
    background: $clr_primary;
    color: $clr_accent;
    transition: 0.3s;
    padding-top: 40px;
  }
  .bm-item-list {
    font-size: 1.8rem;
    padding: 1em 2.3rem;
    margin-left: 0;
    li {
      margin-top: 0.5em;
    }
    a {
      word-break: keep-all;
      color: $clr_accent;
    }
  }
}
#overlay {
  position: fixed;
  background: rgba($clr_black, 0.5);
  z-index: 998;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
}
</style>

<style lang="scss">
// note:https://github.com/mbj36/vue-burger-menu/issues/36
body {
  &.bm-overlay {
    .bm-menu {
      width: 30% !important;
      @media screen and (max-width: 599px) {
        width: 30rem !important;
      }
    }
  }
}
</style>
