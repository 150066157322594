<template>
  <section class="shop" id="shop-section">
    <h2><img src="@/assets/img/heading_shop.png" alt="店舗情報" /></h2>
    <ul class="shop-list">
      <li>
        <img src="@/assets/img/shop01.jpg" />
      </li>
      <li>
        <img src="@/assets/img/shop02.jpg" />
      </li>
      <li>
        <img src="@/assets/img/shop03.jpg" />
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: "ShopSection",
  components: {},
  data() {
    return {
      items: [],
    };
  },
};
</script>
<style lang="scss" scoped>
.shop {
  &-list {
    @include flex;
    margin: 0 2%;
    li {
      width: 32%;
    }
  }
  @media screen and (max-width: 599px) {
    &-list {
      li {
        margin: 1% 0;
        width: 100%;
      }
    }
  }
}
</style>
