<template>
  <div id="app">
      <Header />
      <router-view/>
      <footer class="g-footer">
        <p class="g-footer-copy">(C)2021 Daishizen CO.,LTD</p>
      </footer>
  </div>
</template>

<script>
import Header from "@/components/Header"

  export default {
    name: "App",
    components: {
      Header,
    },
    metaInfo() {
      return {
        title: this.$defaultTitle,
        titleTemplate: this.$defaultTitleTemplate,
      }
    },
  };
</script>

<style lang="scss">
#app {
  background-image: url('~@/assets/img/bg01.jpg');
  background-size: contain;
}
.g-footer {
  background: $clr_primary;
  &-copy {
    padding: 0.5em 0;
    text-align: center;
    color: $clr_white;
    font-size: 12px;
  }
}
</style>
