<template>
  <section class="sns" id="sns-section">
    <h2><img src="@/assets/img/heading_sns.png" alt="SNS" /></h2>
    <div class="sns-body">
      <ul class="sns-list">
        <li v-for="(link, i) in snsLinks" :key="i">
          <a :href="link.url" target="_blank">
            <img :src="link.img" />
          </a>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "SnsSection",
  components: {},
  props: {
    snsLinks: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.sns {
  &-body {
    margin: 2rem 3%;
  }
  &-list {
    @include flex($justifyContent: flex-start);
    width: 45%;
    li {
      & + li {
        margin-left: 5%;
      }
    }
  }
  @media screen and (max-width: 599px) {
    &-body {
      margin: 1rem auto;
    }
    &-list {
      width: 92%;
      margin: 1rem auto;
      li {
        & + li {
          margin-left: 2rem;
        }
      }
    }
  }
}
</style>
