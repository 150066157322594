<template>
  <section class="sponsor">
    <agile :options="slideOptions" v-if="items.length">
      <div v-for="item in items" :key="item.id" class="slide">
        <a v-if="item.url" :href="item.url" :target="item.target">
          <img :src="mappedMedia[item.id].banner[0].url" :alt="item.title" />
        </a>
        <img v-else :src="mappedMedia[item.id].banner[0].url" :alt="item.title" />
      </div>
    </agile>
    <div class="logo">
      <img src="@/assets/img/logo.png" alt="" />
    </div>
  </section>
</template>

<script>
import { VueAgile } from "vue-agile";
import utils from "@/mixins/utils";

export default {
  name: "SponsorSection",
  components: {
    agile: VueAgile,
  },
  mixins: [utils],
  data() {
    return {
      items: [],
      slideOptions: {
        autoplay: true,
        autoplaySpeed: 10000,
        fade: false,
        dots: true,
        centerMode: false,
        navButtons: false,
        infinite: true,
        slidesToShow: 1,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
            },
          },
        ],
      },
    };
  },
  computed: {
    mappedMedia() {
      return this.$_mappingMedia(this.items);
    },
  },
  created() {
    this.fetchSponsorData();
  },
  methods: {
    fetchSponsorData() {
      this.$http
        .get(`sponsor`)
        .then((res) => {
          this.items = res.data.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.sponsor {
  width: 94%;
  margin: 4rem auto 0;
  .slide img {
    max-width: 96%;
  }
  @media screen and (max-width: 599px) {
    .slide img {
      width: 100%;
      max-width: unset;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .logo {
    width: 92%;
    max-width: 18rem;
    margin: auto;
    padding: 1.5em 0;
    text-align: center;
  }
}
.agile::v-deep {
  .agile__dots {
    bottom: 10px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
  .agile__dot {
    margin: 0 10px;
    button {
      background-color: transparent;
      border: 1px solid white;
      border-radius: 50%;
      cursor: pointer;
      display: block;
      height: 10px;
      font-size: 0;
      line-height: 0;
      margin: 0;
      padding: 0;
      transition-duration: 0.3s;
      width: 10px;
    }
    &--current,
    &:hover {
      button {
        background-color: white;
      }
    }
  }
}
</style>
