<template>
  <section class="event" id="event-info-section">
    <h2><img src="@/assets/img/heading_event.png" alt="イベント情報" /></h2>
    <ul class="event-cards">
      <li class="card" v-for="item in items" :key="item.id">
        <p class="card-ttl">{{ item.title }}</p>
        <p class="card-date">{{ item.published_at | format_date }}</p>
        <div
          v-if="mappedMedia[item.id].image && mappedMedia[item.id].image.length"
          class="card-tmb"
        >
          <a v-if="item.url" :href="item.url" :target="item.target">
            <img :src="mappedMedia[item.id].image[0].url" :alt="item.title" />
          </a>
          <img v-else :src="mappedMedia[item.id].image[0].url" :alt="item.title" />
        </div>
        <div class="card-txt">
          <p>{{ item.body }}</p>
        </div>
        <button class="btn" type="button" @click="showModal(item)">詳細</button>
      </li>
      <modal
        name="detail-modal"
        :adaptive="true"
        :scrollable="true"
        height="auto"
        width="75%"
        :max-width="800"
        transition="fade"
        @before-open="modal.isOpened = true"
        @before-close="modal.isOpened = false"
      >
        <div class="detail-modal">
          <p class="card-ttl">{{ modal.item.title }}</p>
          <p class="card-date">{{ modal.item.published_at | format_date }}</p>
          <div v-if="modal.item.src" class="card-img">
            <a
              v-if="modal.item.url"
              :href="modal.item.url"
              :target="modal.item.target"
            >
              <img :src="modal.item.src" :alt="modal.item.title" />
            </a>
            <img v-else :src="modal.item.src" :alt="modal.item.title" />
          </div>
          <div class="card-txt">
            <p>{{ modal.item.body }}</p>
          </div>
          <button
            type="button"
            class="modal-close"
            @click="$modal.hide('detail-modal')"
          >
            &times;
          </button>
        </div>
      </modal>
      <transition name="fade" appear>
        <div v-show="modal.isLoading" class="spinner-loading">
          <font-awesome-icon
            :icon="['fas', 'spinner']"
            size="3x"
            spin
          ></font-awesome-icon>
        </div>
      </transition>
    </ul>
    <prev-next
      :scroll-to-element="'#event-info-section'"
      :currentPage="pagination.current_page"
      :totalPage="pagination.total_pages"
      :targetPage="targetPage"
    />
  </section>
</template>

<script>
import dayjs from "dayjs";
import PrevNext from "@/components/pagination/PrevNext";
import utils from "@/mixins/utils";

export default {
  name: "EventInfoSection",
  components: { PrevNext },
  mixins: [utils],
  data() {
    return {
      items: [],
      pagination: {
        current_page: 1,
        total_pages: 1,
        per_page: 4,
      },
      modal: {
        item: {
          src: null,
        },
        isLoading: false,
        isOpened: false,
      },
      targetPage: "eventPage",
    };
  },
  computed: {
    mappedMedia() {
      return this.$_mappingMedia(this.items);
    },
  },
  filters: {
    format_date(date) {
      return dayjs(date).format("YY/MM/DD");
    },
  },
  watch: {
    "$route.query.eventPage"(to) {
      if (to) {
        this.fetchEventData(to);
      }
    },
  },
  created() {
    const eventPage = this.$route.query.eventPage || 1;
    this.fetchEventData(eventPage);
  },
  methods: {
    fetchEventData(eventPage) {
      this.$http
        .get(`event?page=${eventPage}&perPage=${this.pagination.per_page}`)
        .then((res) => {
          this.items = res.data.data;
          this.pagination = res.data.meta.pagination;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    showModal(item) {
      this.modal.item = Object.assign({}, item);
      this.modal.isLoading = true;
      if (item.media.length) {
        const preloadImage = new Image();
        preloadImage.onload = () => {
          this.$modal.show("detail-modal");
          this.modal.isLoading = false;
        };
        preloadImage.src = this.modal.item.src =
          this.mappedMedia[item.id].image[0].url;
      } else {
        this.$modal.show("detail-modal");
        this.modal.item.src = null;
        this.modal.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.event {
  &-cards {
    @include flex($alignItems: flex-start);
    margin: 2rem 5rem;
    .card {
      width: 48%;
      padding: 1em;
      background: rgba($clr_black, 0.5);
      color: #e1dada;
      &:nth-child(n + 3) {
        margin-top: 4%;
      }
      &-ttl {
        margin-bottom: 0.25em;
        padding-bottom: 0.25em;
        border-bottom: 1px solid #807979;
        font-weight: 500;
      }
      &-date {
        color: $clr_accent;
      }
      &-tmb {
        width: 100%;
        padding-top: 90%;
        position: relative;
        margin: 1em auto 2em;
        text-align: center;
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: contain;
        }
      }
      &-txt {
        font-weight: 500;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
      .btn {
        display: block;
        width: 6rem;
        height: 3.5rem;
        margin: 1em 1em 0.5em auto;
        line-height: 3.2rem;
        border-radius: 0.25em;
        border: 1px solid $clr_white;
        color: $clr_white;
        text-align: center;
        font-weight: normal;
        letter-spacing: 2px;
        cursor: pointer;
      }
    }
    @media screen and (max-width: 599px) {
      margin: 1rem 4%;
      .card {
        width: 100%;
        & + .card {
          margin-top: 4%;
        }
      }
    }
  }
}
.detail-modal {
  width: auto;
  padding: 1em;
  background: $clr_black;
  color: #e1dada;
  .card {
    &-txt {
      -webkit-line-clamp: unset;
    }
    &-img {
      text-align: center;
      margin: 2rem 0;
      img {
        object-fit: contain;
        max-width: 100%;
      }
    }
  }
  .modal-close {
    position: absolute;
    top: 0.5%;
    right: 1.5%;
    color: $clr_white;
    font-size: 1.8rem;
    font-weight: 700;
    cursor: pointer;
  }
}
.spinner-loading {
  position: fixed;
  left: 50vw;
  top: 50vh;
  color: white;
  z-index: 9999;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.vm--container::v-deep {
  @media screen and (max-width: 599px) {
    .vm--modal {
      left: 5% !important;
      width: 90% !important;
    }
  }
}
</style>
